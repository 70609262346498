<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Survey Details</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <Editor :editMode="editMode" :itemsData="itemsData" :initial="dataItem" @data="save" />
        </v-card-text>
      </v-card>
    </v-container>
</template>
<script>
import Editor from "../../_components/rules/editor.vue";
export default {
  components: {
    Editor
  },
  data() {
    return {
      dataItem: {},
      itemsData: {},
      editMode: true
    };
  },
  watch: {
    "$route.params.response_id": {
      handler: "getResponses",
      immediate: true
    }
  },
  methods: {
    getResponses(id) {
      const self = this;
      this.loading = true;
      console.log(id, "items");
      this.$store
        .dispatch("get", `/getchoices/${id}`)
        .then(res => {
          self.itemsData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      //logic comes here
      console.log(this.$route, "route");
      const url = `/rules/${this.$route.params.response_id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(res => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/survey");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>
